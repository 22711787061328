import { graphql } from "gatsby"
import React from "react"
import Nav from 'components/pages/Curriculum/components/Nav'
import Header from 'components/pages/Curriculum/components/Header'
import Content from 'components/pages/Curriculum/components/Content'
import SEO from 'components/SEO'
import DynamicZone from 'components/Strapi/DynamicZone'
import Layout from "../layouts/Curriculum"

interface IProps {
  path: string,
  data: {
    site: {
      siteMetadata: {
        siteUrl: string
      }
    }
    strapiResourceCategories: Strapi.ResourceCategory
  }
}

const ResourceCategoryTemplate = (props: IProps) => {
  const category = props.data.strapiResourceCategories
  const pages = category.resource_pages

  const primary = category.Header?.bgGradient?.from

  const SEOProps = {
    title: category.SEO?.title,
    titleTemplate: "%s | Night Zookeeper",
    image: category.SEO?.image?.url,
    url: category.SEO?.url,
    canonical: category.SEO?.canonical || `${props.data.site.siteMetadata.siteUrl}${props.path}`,
    noIndex: category.SEO?.noIndex || false,
    description: category.SEO?.description,
  }

  return (
    <Layout>
      <SEO {...SEOProps} titleTemplate='%s | Night Zookeeper' />
      <Nav items={[
        { slug: `${category.slug}`, title: `${category.title}`, to: `${category.customPath || '/resources'}/${category.slug}`},
        ...pages.map(p => ({
          slug: p.slug,
          title: p.title,
          to: `${category.customPath || '/resources'}/${category.slug}/${p.slug}`
        }))]} selected={`${category.slug}`} headerColor={primary || '#A5326C'} />
      <Header {...category.Header} />
      <Content content={category.content} primary={primary} />
      <DynamicZone content={category.sections || []} />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    strapiResourceCategories(slug: { eq: $slug }) {
      slug
      title
      customPath
      resource_pages(sort: { fields: slug, order: ASC }) {
        id
        title
        slug
      }
      Header {
        title
        subTitle
        button {
          id
          value
          theme
          targetUrl
        }
        image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(width: 660, placeholder: BLURRED)
            }
          }
        }
        bgGradient {
          from
          to
          angleDegree
        }
      }
      content
      sections {
        ...BookHero
        ...ZooHero
        ...ParentProductHero
        ...TeacherHero
        ...ShopHero
        ...PartnersGroup
        ...ExplanationHero
        ...TitleSeparator
        ...EngagingBanner
        ...BigIconsHero
        ...RatingsHero
        ...HowItWorks
        ...QuoteHero
        ...PlansHero
        ...PlanHero
        ...CarouselHero
        ...QuotesCarousel
        ...TeacherCTAHero
        ...FAQSection
        ...Partners
        ...Strapi_RichSection
        ...FixedBanner
        ...CTAStrip
        __typename
      }
      SEO {
        title
        description
        url
        noIndex
        image {
          url
        }
        canonical
      }
    }
  }
`

export default ResourceCategoryTemplate
